@import url('https://fonts.googleapis.com/css2?family=Gowun+Batang&family=Inter&family=Rajdhani:wght@300&family=Ubuntu&display=swap');

body {
    font-family: 'Ubuntu', sans-serif;
}


/* Add this CSS to your existing styles */
.styled-table tbody tr:nth-child(even) {
    background-color: #94bab4cc;
    /* Even row color */
}

.styled-table tbody tr:nth-child(odd) {
    background-color: #b3b9d2d1;
}

.styled-table td {
    padding: 10px 16px;
    border-radius: 8px;
}


.result-table tbody tr:nth-child(even) {
    background-color: #a6ba94;
    /* background-color: #4370a678; */
}

.result-table tbody tr:nth-child(odd) {
    background-color: #deb572a8;
}

.result-table td {
    padding: 8px 16px;
    border-radius: 8px;
}


.result-table {
    font-size: 20px;
}